<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-can="'permission.index'"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-btn
        id="btn2"
        color="primary"
        class="mx-2"
        dark
        @click="downloadPDF()"
      >
        <v-icon dark>
          mdi-printer
        </v-icon>
        طباعة
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        v-max-length="200"
        append-icon="mdi-magnify"
        :label="$t('dataTable.headers.search')"
        hide-details
        class="search-input"
        @change="fetch"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="permissions"
      :search="search"
      :options.sync="options"
      :server-items-length="permissionsTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      @update:options="fetch"
    >
      <template v-slot:item.name="{ item }">
        <code>
          {{ item.name }}
        </code>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <permission-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    >
    </permission-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import PermissionDialog from '@/views/pages/roles-permissions/permissions/dialog.vue'
import { ObjectToQuery } from '@/plugins/helper'
import MenuItem from '@/components/menu/menuItem.vue';

export default {
  components: { PermissionDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.roles',
    filters: false,
    permissions: [],
    permissionsTotal: 0,
    loading: true,
    options: {},
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$('table.id'),
          align: 'center',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$('table.description'),
          value: 'description',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$('table.code'),
          value: 'name',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$('table.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    search: {
      handler() {
        this.fetch();
      },
    },
  },
  mounted() {},
  methods: {
    async fetch() {
      window.scrollTo(0, 0);
      this.loading = true;

      // const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        search: this.search,
      })
      const response = await axios.get(`/permissions/dt?${query}`);
      this.permissions = response.data.data.data
      this.permissionsTotal = response.data.data.total
      this.loading = false
    },
    downloadPDF() {
      window.print()
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>

<style>
@media print {

}
</style>
